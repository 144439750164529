.axis {
  color: #A7AFBB;

  text {
    font-size: 12px;
  }

  .tick {
    line {
      stroke: #E5EBF0;
    }
    &.active {
      color: #268e97;
      fill: #268e97;
      line {
        stroke: #268e97;
      }
    }
  }
}

.axisLine {
  stroke: #E5EBF0;
  stroke-width: 1;
}

.dataPoint {
  fill: white;
  stroke-width: 3;
}

.average, .min, .max {
  stroke: #E5EBF0;
}

.average {
  stroke-width: 2;
}

.guidelines {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
  &.visible {
   opacity: 1;
}
}

.mapLabel {
  top: 0;
  right: 0;
}
